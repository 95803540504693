import request from '@/utils/request'

export function getDictType(data) {
  return request({
    url: '/com/sys-dict/sel?id='+data,
    method: 'get'
  })
}
export function getDictTypeById(data) {
  return request({
    url: '/com/sys-dict/selName',
    method: 'get',
    params:data!==null?{id:data}:undefined
  })
}

