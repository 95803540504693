import request from '@/utils/request'

export function getStatistical() {
    return request({
        url: '/com/statistical/sel',
        method: 'get'
    })
}
export function getStatisticalQQ() {
    return request({
        url: '/sysConfig/getQQ',
        method: 'get'
    })
}