<template>
  <div>
    <div style="display:flex;">
      <div class="home-top">
        <div class="home-top-left-nav">
          <div
              class="home-top-left-nav-item"
              v-for="(item, index) in leftNav"
              :key="index"
          >
            <div class="home-top-left-nav-title">{{ item.title }}</div>
            <div class="home-top-left-nav-list">
              <router-link
                  :to="{ path: item.url, query: { type: nav.id } }"
                  v-for="nav in item.childrens"
                  :key="nav.id"
                  class="home-top-left-nav-list-item"
              >{{ nav.name }}
              </router-link>
            </div>
          </div>
        </div>
        <!--        <img src="@/assets/image/bannerA.jpg" alt=""/>-->
        <a-carousel arrows autoplay>
          <div><img src="@/assets/image/bannerB.jpg" alt=""/></div>
          <div><img src="@/assets/image/bannerA.jpg" alt=""/></div>
          <div><img src="@/assets/image/bannerC.jpg" alt=""/></div>
          <div><img src="@/assets/image/bannerD.jpg" alt=""/></div>
        </a-carousel>
      </div>

    </div>
    <div class="home-bottom">
      <div class="home-count-item" :style="{color:iconColor[index]}" v-for="(item,index) in Statistical" :key="index">
        <img :src="require(`../../assets/image/icon${index+1}.png`)" alt=""/>
        <div>{{ item.name }}({{ item.unit }})</div>
        <div class="home-count-item-num">{{ item.number }}</div>
      </div>
    </div>

    <div class="btn_content">
      <div class="btn_data">
        <div class="btn_padding">
          <router-link
              :to="{path:'/science/science'}">
            <div class="btn_img"><img src="../../assets/image/avctive.png"/></div>
            <span class="btn_font">活动报名</span>
          </router-link>
        </div>
      </div>
      <div  v-if="qq!==''&&qq!==null" class="btn_data" @click="OpenServer" style="margin-top: 3px">
        <div class="btn_padding">
          <div class="btn_img"><img src="../../assets/image/serve.png"/></div>
          <span class="btn_font">
                联系客服
              </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getDictType} from "../../api/company/dictType";
import {getStatistical,getStatisticalQQ} from "../../api/company/statistical";

const iconColor = ["#199DAE", "#00A38A", "#13A9A7", "#2C82C4", "#41ABB7", "#17B6CD", "#14A9AB", "#159F56", "#35AA0C", "#2AA7BD", "#12A886", "#1FABB4"];
export default {

  data() {
    return {
      qq:null,
      Statistical: [],
      leftNav: [
        {
          id:5,title: "找技术", url: 'specialty', childrens: [{id: 0, name: ''}]
        },
        {
          id:6,title: "找资金", url: 'funds', childrens: [{id: 0, name: ''}]
        },
        {
          id:7,title: "找服务", url: 'service', childrens: [{id: 0, name: ''}]
        },
        {
          id:8,title: "找场地", url: 'site', childrens: [{id: 0, name: ''}]
        },
        {
          id:9,title: "找专家", url: 'expert', childrens: [{id: 0, name: ''}]
        },
      ],
      iconColor: iconColor
    };
  },
  methods: {
    OpenServer() {
      window.open('http://wpa.qq.com/msgrd?v=3&uin='+this.qq+'&site=qq&menu=yes')
    },

    init() {
      getStatisticalQQ().then(res=>{
                 this.qq=res.msg;
      })
      getStatistical().then(res => {
         let arr= res.data;
         this.Statistical=arr.slice(0,12);
      })
    },
    getNavItem() {
      //console.log(this.leftNav)
      this.leftNav.forEach(item => {
        getDictType(item.id).then(res => {
          item.childrens = res.data;
        })
      })
      //console.log(this.leftNav)
    },
  },
  mounted() {
    this.init();
    this.getNavItem();
    //弹出登陆框
    if(this.$route.query.pas==='1'){
       this.$parent.login();
    }
  }
};
</script>
<style lang="scss">
.btn_content {
  position: fixed;
  z-index: 100;
  right: 50px;
  top: 40%;

  .btn_data {
    width: 86px;
    height: 86px;
    background-color: #C8D3E6;
    cursor: pointer;

    .btn_padding {
      padding: 10px;
    }

    .btn_img {
      text-align: center
    }

    .btn_font {
      color: #FFFFFF;
      font-size: 16px
    }
  }

  .btn_data:hover {
    background-color: #075fea;
  }
}

.home-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  .home-top-left-nav {
    width: 300px;
    height: 524px;
    background: #fcfcfc;
    border: 2px solid #ff9a02;
    padding: 20px;

    .home-top-left-nav-item {
      border-bottom: 1px solid #dddddd;
      // line-height: 36px;
      margin-bottom: 10px;

      .home-top-left-nav-title {
        font-size: 18px;
        color: #333333;
        font-weight: 600;
      }

      .home-top-left-nav-list {
        display: flex;
        flex-wrap: wrap;
        padding: 4px 0;
        // justify-content: space-between;
        .home-top-left-nav-list-item {
          // background-color: #044fc6;
          font-size: 14px;
          color: #666666;
          cursor: pointer;
          user-select: none;
          padding-right: 28px;
          line-height: 30px;
        }
        a:hover{
          color: #ff9a02;
        }
      }
    }

    .home-top-left-nav-item:last-child {
      border-bottom: 0 solid #dddddd;
    }
  }

  .ant-carousel {
    width: 885px;
    height: 509px;
    .slick-dots-bottom{
      bottom:30px;
    }
    img{
      width: 100%;
      height: 509px
    }
  }

}

.home-bottom {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: space-between;

  .home-count-item {
    width: 16%;
    height: 170px;
    text-align: center;
    border: #EEEEEE 1px solid;
    padding-top: 20px;
    font-size: 18px;
    line-height: 45px;
    margin-top: 10px;
    background: linear-gradient(-38deg, transparent 138px, rgb(248 251 255) 0) right,
    linear-gradient(45deg, transparent 0px, rgb(242 247 255) 0) left;

    .home-count-item-num {
      font-size: 28px;
      font-weight: 600;
      line-height: 20px;
    }
  }
}


</style>
